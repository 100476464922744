<!--热门餐厅订单列表-->
<template>
    <div class="body">
        <van-list
                v-model="listLoading"
                :finished="listLoadingFinished"
                finished-text="没有更多了"
                @load="onLoad"
        >
            <div style="margin:0 0 2vw 0" class="order_content" v-for="(item, index) in listLoadingList" :key="index">
                <div class="div4">
                    <div class="div5">
                        <div class="div6">
                            <p>五折餐厅:{{item.title}}</p>
                        </div>
                        <p class="p2">使用时间：{{item.create_time}}</p>
                        <p class="p2">总价：￥{{moneyFen2Yuan(item.total_fee)}}</p>
                    </div>
                </div>
            </div>
        </van-list>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import { globalConfig, listLoading } from '../../utils/mixin'
  import { moneyFen2Yuan } from '../../utils/tools'
  import FloatNav from '../../components/common/FloatNav'

  export default {
    name: 'HotOrder',
    components: { FloatNav },
    mixins: [globalConfig, listLoading],
    methods: {
      moneyFen2Yuan: function (money) {
        return moneyFen2Yuan(money)
      }
    },
    created () {
      this.listLoadingInit(this.apiUrl + 'mall/hot/getHotOrderList', 'list', 1, {})
    }
  }
</script>

<style scoped>
    .body {
        position: absolute;
        min-height: 100%;
        min-width: 100%;
        background-color:#eee;
    }
    .order_content{
        background-color: #FFFFff;
    }
    .div4{
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        border-bottom: 0.01rem solid #eeeeee;
        background-color: #ffffff;
        padding: 1rem;
    }
    .div5{
        width: 80%;
        display: flex;
        flex-flow: column;
    }
    .div6{
        font-size: 4.2vw;
        font-weight: 400;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 88vw;
    }
    .p2 ,.div7 > p:nth-child(1) {
        font-size: 0.9rem;
        color: #898989;
    }
</style>
